import React from "react";
import styled from "styled-components";

// Utils
import { Font, Color, rem, rgba, responsive } from "../../utils/style";

// Components
import MagicLink from "../MagicLink";

// Styled Elements
const StyledButton = styled(MagicLink)`
  box-sizing: border-box;
  padding: 0 24px;
  border-radius: 26px;
  height: 50px;
  cursor: pointer;
  background-color: ${Color.ritualBlue};
  border: 2px solid ${Color.ritualBlue};
  margin: 0;
  transition: all 0.2s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;

  ${responsive.sm`
    padding: 0 30px;
  `};

  * {
    font-weight: 500;
  }

  &.bordered {
    background-color: transparent;
  }

  &.short {
    height: 40px;
  }

  &.fullwidth {
    width: 100%;
  }

  &.long-text {
    padding: 0 20px;

    ${responsive.sm`
      padding: 0 20px;
    `}
  }

  &:hover {
    border-color: ${Color.ritualYellow} !important;
    background-color: ${Color.ritualYellow} !important;

    span {
      color: ${Color.ritualBlue} !important;

      &:before {
        background-color: ${Color.ritualBlue} !important;
      }
    }
  }

  &.alt-hover {
    &:hover {
      border-color: ${Color.white} !important;
      background-color: ${Color.white} !important;

      span {
        color: ${Color.ritualBlue} !important;

        &:before {
          background-color: ${Color.ritualBlue} !important;
        }
      }
    }
  }

  &.primary-cta {
    background-color: white !important;
    border: none !important;
    color: ${Color.ritualBlue} !important;
    &:hover {
      color: white !important;
      background-color: ${Color.ritualBlue} !important;

      span {
        color: white !important;
      }
    }

    span {
      color: ${Color.ritualBlue} !important;
    }
  }

  &.yellow-cta {
    background-color: ${Color.ritualYellow} !important;
    border: none !important;
    color: ${Color.ritualBlue} !important;

    span {
      color: ${Color.ritualBlue} !important;

      &:before {
        background-color: ${Color.ritualBlue} !important;
      }
    }

    &:hover {
      color: white !important;
      background-color: ${Color.ritualBlue} !important;

      span {
        color: ${Color.white} !important;

        &:before {
          background-color: ${Color.white} !important;
        }
      }
    }

    &.disabled {
      background-color: ${Color.ritualBlue} !important;

      span {
        color: ${Color.white} !important;

        &:before {
          background-color: ${Color.white} !important;
        }
      }
    }
  }

  &.disabled {
    opacity: 0.24;
    cursor: default;
    pointer-events: none;

    &:hover {
      background-color: ${Color.ritualBlue} !important;
      border: 2px solid ${Color.ritualBlue} !important;

      span {
        color: white !important;

        &:before {
          background-color: white !important;
        }
      }
    }
  }

  &.outlined {
    background-color: white;
    border: 1px solid ${rgba(Color.ritualBlue, 0.16)} !important;

    span {
      color: ${Color.ritualBlue};
    }

    &:hover {
      background-color: ${Color.ritualBlue} !important;

      span {
        color: white !important;
      }

      &:before {
        background-color: ${Color.ritualBlue} !important;
      }
    }
  }
`;

const StyledTextWrapper = styled.div`
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const StyledText = styled.span`
  ${Font.circular}
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 500;
  color: ${Color.white};
  transition: all 0.2s;
  display: flex;
  align-items: center;
  align-content: center;

  &.short {
    font-size: ${rem(14)};
    line-height: ${rem(24)};
  }

  .isvg {
    height: auto;
    display: flex;
  }

  &.outlined {
    font-size: ${rem(14)};
    line-height: ${rem(24)};

    ${responsive.md`
      font-size: ${rem(16)};
      line-height: ${rem(26)};
    `}
  }

  &.bordered {
    color: ${Color.ritualBlue};

    span {
      color: ${Color.ritualBlue};

      &:before {
        background-color: ${Color.ritualBlue};
      }
    }
  }
`;

const AppendText = styled.span`
  position: relative;
  ${Font.circular}
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 500;
  color: ${Color.white};
  transition: all 0.2s;
  padding-left: 10px;
  margin-left: 8px;

  &.short {
    font-size: ${rem(14)};
    line-height: ${rem(24)};

    &:before {
      height: 16px;
    }
  }

  &:before {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    height: 24px;
    left: 0;
    width: 1px;
    background-color: ${Color.white};
    transition: all 0.2s;
    opacity: 0.56;
  }
`;

export default class MagicButton extends React.Component {
  render() {
    let {
      to,
      state,
      href,
      children,
      className,
      target,
      title,
      append,
      onClick,
      ariaLabel,
      id,
      disabled,
    } = this.props;

    if (disabled) {
      className = `${className || ""} disabled`;
    }

    let settings = {
      to,
      state,
      href,
      title,
      target,
      className,
      ariaLabel,
      id,
    };

    if (onClick) {
      settings.onClick = disabled ? null : onClick;
    }

    return (
      <StyledButton id={id} {...settings}>
        <StyledTextWrapper>
          <StyledText className={className}>{children}</StyledText>
          {append && <AppendText className={className}>{append}</AppendText>}
        </StyledTextWrapper>
      </StyledButton>
    );
  }
}
